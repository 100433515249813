import React from "react";
import PropTypes from "prop-types";

import Brochure from "./brochure";

import Overlay from "./overlay";
import actionPropType from "../prop-types/action";
import $ from "jquery";

class ActionGatedContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal(e) {
    e.preventDefault();

    const { showModal } = this.state;

    this.setState({ showModal: !showModal });
  }

  getClasses() {
    const { action, isFull } = this.props;

    // let classes = 'Button  ';
    const classes = ["Button"];

    if (action.type) {
      classes.push(`Button--${action["type"]}`);
    }

    if (action.classes && action.classes.indexOf("Button") === -1) {
      classes.push(`${action.classes}`);
    }

    if (action.style) {
      classes.push(`${action.style}`);
    }

    if (isFull) {
      classes.push(`Button--full`);
    }

    if (action.url && action.url.charAt(0) === "#") {
      classes.push(`smooth-scroll`);
    }

    return classes.join(" ");
  }

  renderLabel(label) {
    if (label !== "Start a free trial") {
      return label;
    }

    if (this.props.responsiveLabel) {
      return $(window).width() < 768 ? "Free trial" : label;
    }

    if (this.props.reducedLabel) {
      return "Free trial";
    }

    return label;
  }

  hideModal() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const { action } = this.props;
    const { showModal } = this.state;
    const brochure = {
      type: "brochure",
      config: {
        verbose: true,
        className: "",
      },
      content: {
        image: {
          src: "",
          alt: "",
        },
        "background-image": "",
        title: "",
        description: "",
        "lead-source": "",
        download: "",
        "submit-label": "Download free eBook",
        "success-title": "Thanks, your download will start soon",
      },
    };

    const classes = this.getClasses();
    action.classes = classes;
    const br = Object.assign({}, brochure);
    br.content.image.src = action.modal_image.url;
    br.content.image.alt = action.modal_image.alt;
    br.content.title = action.modal_title;
    br.content.download = action.download.link;
    br.content["lead-source"] = action["lead_source"];
    br.config.className = action.lightModal
      ? "Brochure--condensed is-dark"
      : "Brochure--condensed";
    // console.log(action['lead_source'], br.content["lead-source"]);

    return (
      <>
        <a
          data-testid='action'
          href='#'
          onClick={this.showModal}
          data-category='engagement'
          data-action='click link'
          data-label={action["url"]}
          className={this.getClasses()}
        >
          {this.renderLabel(action["label"])}
        </a>
        <Overlay
          show={showModal}
          lightModal={action.lightModal}
          handleClose={this.hideModal}
        >
          <div className='container'>
            <Brochure block={br} />
          </div>
        </Overlay>
      </>
    );
  }
}


ActionGatedContent.propTypes = {
  action: actionPropType,
  reducedLabel: PropTypes.bool,
  responsiveLabel: PropTypes.bool,
  isFull: PropTypes.bool,
};

export default ActionGatedContent;
