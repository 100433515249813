import React from 'react';
import PropTypes from 'prop-types';
import Overlay from './overlay';
import Link from 'next/link';
import isInternalLink from '../utils/is-internal-link';
import Portal from '../portal';

import classNames from 'classnames';

import actionPropType, { ActionBehaviour } from '../prop-types/action';

import ActionGatedContent from './action-gated-content';
import { PageContext } from '../context/page-context';

const SIGN_UP_URL = 'https://app.gettimely.com/register';

const ActionIcon = {
    ARROW_RIGHT_CHEVRON: '\\f105',
};

class Action extends React.Component {
    constructor(props, context) {
        super(props);
        this.showVideo = this.showVideo.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openChatPopper = this.openChatPopper.bind(this);
        this.openCallModal = this.openCallModal.bind(this);
        this.state = {
            openVideo: false
        };
    }

    getClasses() {
        const { action, isFull, className } = this.props;

        // let classes = 'Button  ';
        const classes = ['Button'];

        if (action.type) {
            classes.push(`Button--${action['type']}`);
        }

        if (action.classes && action.classes.indexOf('Button') === -1) {
            classes.push(`${action.classes}`);
        }

        if (action.behaviour === 'modal-book-a-call') {
            classes.push('btn-nav-call');
        }

        if (action.behaviour === 'consult-modal') {
            classes.push('consult-modal');
            action.url="#";
        }

        if (action.style) {
            classes.push(`${action.style}`);
        }

        if (isFull) {
            classes.push(`Button--full`);
        }

        if (className) {
            classes.push(className);
        }

        if (action.icon) {
            if (action.icon === ActionIcon.ARROW_RIGHT_CHEVRON) {
                classes.push('Button--icon-arrow-right-chevron');
            }
        }

        return classes;
    }

    getClassNames() {
        return classNames(this.getClasses());
    }

    /**
     * Returns the desired value of the `target` attribute, if any, for internal links.
     *
     * @returns {string | undefined}
     */
    getTarget() {
        const { action } = this.props;
        const url = new URL(action.url, process.env.NEXT_PUBLIC_ROOTURL);

        if ((/\.pdf$/).test(url.pathname)) {
            return '_blank';
        }
    }

    isSignUpButton(action) {
        if (action.behaviour === ActionBehaviour.CTA_FREE_TRIAL) {
            action.url = SIGN_UP_URL;
            return true;
        } else if (
            action.url === SIGN_UP_URL &&
            [
                'start your free trial',
                'start a free trial',
                'start free trial',
            ].indexOf(action.label.toLowerCase()) !== -1
        ) {
            return true;
        }
    }

    isExternal(action) {
        const classNames = action.classNames;
        const isExternal = classNames.indexOf('external') !== -1;
        return isExternal;
    }

    isVideo(action) {
        const classNames = action.classNames;
        const isVideo = classNames.indexOf('video') !== -1;
        return isVideo;
    }

    isLiveChatPopper(action) {
        const classNames = action.classNames;
        const isLiveChatPopper = classNames.indexOf('livechat-popper') !== -1;
        return isLiveChatPopper;
    }

    isNavCall(action) {
        const classNames = action.classNames;
        const isNavCall = action.behaviour === 'modal-book-a-call' ||
            classNames.indexOf('btn-nav-call') !== -1 ||
            classNames.indexOf('pricing-enterprise-cta') !== -1;
        return isNavCall;
    }

    isDownloadButton(action) {
        const classNames = action.classNames;
        const isDownloadButton = classNames.indexOf('download') !== -1;
        return isDownloadButton;
    }

    openChatPopper(e) {
        e.preventDefault();
        const Beacon = window.Beacon;
        if (typeof Beacon === 'function') {
            Beacon('toggle');
        }
        return false;
    }

    showBuyNow(action) {
        // TODO: Use persona function when webinar is merged
        const trialTypes = ['TrialEnded', 'Trialing', 'Test', 'Active'];
        const {
            business_status
        } = this.context;

        if (!this.isSignUpButton(action)) {
            return false;
        }

        if (
            trialTypes.includes(business_status)
        ) {
            return true;
        }
    }

    openCallModal(e) {
        // e.stopPropagation();
        // e.nativeEvent.stopImmediatePropagation();
        // $('.form-modal-buy-now').addClass('ignore');
        // $('.form-modal-standard').removeClass('ignore');
        // setTimeout(function() {
        //     $('html').addClass('freeze');
        //     $('.time-bar').change();
        //     $('.form-modal-standard')
        //         .removeClass('demo')
        //         .addClass('call');
        //     $('.form-modal-standard .marketingsource').val(
        //         'Request a Callback'
        //     );
        //     setTimeout(function() {
        //         $('html').addClass('frozen');
        //     }, 2000);
        // }, 0);
        // return false;
    }

    renderLabel(label) {
        if (label !== 'Start a free trial') {
            return label;
        }

        if (this.props.responsiveLabel && typeof window !== 'undefined') {
            return window.innerWidth < 768 ? 'Free trial' : label;
        }

        if (this.props.reducedLabel) {
            return 'Free trial';
        }

        return label;
    }

    // Todo: fix video
    showVideo(e) {
        e.preventDefault();
        this.setState({
            openVideo: true
        });

        // const { action } = this.props;

        // $('#VideoOverlay-iframe').attr('src', action.url);
        // $('.Video-Overlay').fadeIn(() => {
        //     $('.Video-Overlay').addClass('notransition');
        //     this.player = new Player('VideoOverlay-iframe');
        // });

        // $('body').addClass('Overlay-blur');
        // $('html').addClass('intercom-hideaway');
        // $('.Overlay-close').on('click', this.closeModal);
    }

    closeModal() {
        this.setState({
            openVideo: false
        });
        // if (this.player) this.player.pause();
        // $('.Video-Overlay').fadeOut('fast');
        // $('body').removeClass('Overlay-blur');
        // $('html').removeClass('intercom-hideaway');
        // $('#VideoOverlay-iframe').attr('src', '');
        document.querySelector("html").classList.remove("Overlay-fixed");
        document.querySelector("body").classList.remove("Overlay-on");
    }


    render() {
        const { action, external } = this.props;
        const { openVideo } = this.state;

        const classes = this.getClasses();
        action.classes = classes;
        action.classNames = this.getClassNames();

        if (this.isLiveChatPopper(action)) {
            return (
                <a
                    data-testid="action"
                    href={action['url']}
                    onClick={this.openChatPopper}
                    data-category="engagement"
                    data-action="click link"
                    data-label={action['url']}
                    className={this.getClassNames()}>
                    {action['label']}
                </a>
            );
        }

        if (this.isExternal(action)) {
            return (
                <a
                    data-testid="action"
                    href={action['url']}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-category="engagement"
                    data-action="click link"
                    data-label={action['url']}
                    className={this.getClassNames()}>
                    {action['label']}
                </a>
            );
        }

        if (this.isNavCall(action)) {
            return (
                <a
                    data-testid="action"
                    href="#"
                    onClick={this.openCallModal}
                    data-category="engagement"
                    data-action="click link"
                    data-label={action['url']}
                    className={this.getClassNames()}>
                    {action['label']}
                </a>
            );
        }

        if (this.showBuyNow(action)) {
            return (
                <a
                    className={this.getClassNames()}
                    data-status="trialing, trialended"
                    data-testid="action"
                    href="https://app.gettimely.com/Subscription/ChoosePlan">
                    Get&nbsp;Started
                </a>
            );
        }

        if (this.isSignUpButton(action) && this.context.contactID) {
            const url = `/share-the-love/sign-up/${this.context.contactID}`;
            return (
                <a
                    data-testid="action"
                    href={url}
                    className={this.getClassNames()}>
                    {this.renderLabel(action['label'])}
                </a>
            );
        }

        if (this.isSignUpButton(action) && this.context.ReferrerID) {
            const url = `/referral/${this.context.ReferrerID}`;
            return (
                <a
                    data-testid="action"
                    href={url}
                    className={this.getClassNames()}>
                    {this.renderLabel(action['label'])}
                </a>
            );
        }

        if (this.isSignUpButton(action)) {
            let plan = false;

            if (this.props.plan) {
                plan = this.props.plan;
                // We renamed the start plan a while ago.. todo: we need to rename the matrix to basic
                if (this.props.plan === 'start') {
                    plan = 'basic';
                }
            }

            const url = plan ? `${action.url}?plan=${plan}` : action.url;

            return (
                <SignUpButton
                    url={url}
                    label={this.renderLabel(action['label'])}
                    className={this.getClassNames()} />
            );
        }

        if (this.isDownloadButton(action)) {
            return (
                <a
                    data-testid="action"
                    href={action['url']}
                    className="Button Button--plain Button--image"
                    rel="noopener noreferrer"
                    target="_blank">
                    <img
                        width="146"
                        src="/wp-content/themes/timely-timber/assets/images/svg/app-store-badge.svg"
                        alt={action['label']}
                    />
                </a>
            );
        }

        if (this.isVideo(action)) {
            return (
                <>
                    <a
                        className="Button Button--link Button--video"
                        onClick={this.showVideo}
                        data-testid="action"
                        href="#">
                        <svg
                            height="31"
                            viewBox="0 0 31 31"
                            width="31"
                            xmlns="http://www.w3.org/2000/svg">
                            <g
                                id="PlayButton-icon"
                                fill="#39464e"
                                fillRule="evenodd"
                                transform="translate(-.5 -.293479)">
                                <path
                                    d="m17.2765923 10.8857498 5.0466107 8.5981064h-10.0932215z"
                                    transform="matrix(0 1 -1 0 32.461395 -2.091789)"></path>
                                <path
                                    d="m15.8046875 30.3586247c-8.28464304 0-15-6.7265092-15-15.0232499 0-8.29674072 6.71535696-15.02324994 15-15.02324994 8.284643 0 15 6.72650922 15 15.02324994 0 8.2967407-6.715357 15.0232499-15 15.0232499zm0-2c7.17933 0 13-5.8303365 13-13.0232499 0-7.19291345-5.82067-13.02324994-13-13.02324994-7.17932995 0-13 5.83033649-13 13.02324994 0 7.1929134 5.82067005 13.0232499 13 13.0232499z"
                                    fillRule="nonzero"></path>
                            </g>
                            <g
                                id="PlayButton-hover"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd">
                                <g
                                    id="12"
                                    transform="translate(-609.000000, -735.000000)">
                                    <g
                                        id="Group-3"
                                        transform="translate(608.500000, 734.706521)">
                                        <g id="Group-4">
                                            <g id="Group-7">
                                                <path
                                                    d="M15.8046875,30.3586247 C7.52004446,30.3586247 0.8046875,23.6321155 0.8046875,15.3353748 C0.8046875,7.03863408 7.52004446,0.312124863 15.8046875,0.312124863 C24.0893305,0.312124863 30.8046875,7.03863408 30.8046875,15.3353748 C30.8046875,23.6321155 24.0893305,30.3586247 15.8046875,30.3586247 Z"
                                                    id="Oval"
                                                    fillRule="nonzero"></path>
                                                <polygon
                                                    id="Triangle"
                                                    fill="#000000"
                                                    transform="translate(17.276592, 15.184803) rotate(-270.000000) translate(-17.276592, -15.184803) "
                                                    points="17.2765923 10.8857498 22.323203 19.4838562 12.2299815 19.4838562"></polygon>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        {action.label}
                    </a>
                    {openVideo &&
                        <Portal>
                            <Overlay
                                show={openVideo}
                                video={true}
                                classes="Overlay--white Overlay--left"
                                handleClose={this.closeModal}>
                                <iframe
                                    id="VideoOverlay-iframe"
                                    src={action.url}
                                    frameBorder="0"
                                    allow="autoplay"
                                    webkitallowfullscreen="true"
                                    mozallowfullscreen="true"
                                    allowFullScreen={true}></iframe>
                            </Overlay>
                        </Portal>
                    }
                </>
            );
        }

        if (action.behaviour === 'modal-gated-content') {
            return <ActionGatedContent action={action} />;
        }

        if (action.behaviour === 'disabled') {
            return (
                <a
                    data-testid="action"
                    href="#"
                    className="Button Button--disabled">
                    {this.renderLabel(action['label'])}
                </a>
            );
        }

        if (external) {
            return (
                <a
                    data-testid="action"
                    href={action['url']}
                    data-category="engagement"
                    data-action="click link"
                    rel="noopener noreferrer"
                    target="_blank"
                    data-label={action['url']}
                    className={this.getClassNames()}>
                    {this.renderLabel(action['label'])}
                </a>
            );
        }

        if (isInternalLink(action['url'])) {
            return (
                <Link href={action['url']}>
                    <a
                        data-testid="action"
                        href={action['url']}
                        target={this.getTarget()}
                        data-category="engagement"
                        data-action="click link"
                        data-label={action['url']}
                        className={this.getClassNames()}>
                        {this.renderLabel(action['label'])}
                    </a>
                </Link>
            );
        }

        return (
            <a
                data-testid="action"
                href={action['url']}
                target={this.getTarget()}
                data-category="engagement"
                data-action="click link"
                data-label={action['url']}
                className={this.getClassNames()}
            >
                {this.renderLabel(action['label'])}
            </a>
        );
    }
}

export function SignUpButton({ url, className, label }) {

    return (
        <a
            href='https://app.gettimely.com/register'
            className={className}>
            {label}
        </a>
    );
    // return (
    //     <Link href='/sweet-sign-up'>
    //         <a
    //             href='/sweet-sign-up'
    //             className={className}>
    //             {label}
    //         </a>
    //     </Link>
    // )
}

Action.propTypes = {
    action: actionPropType,
    reducedLabel: PropTypes.bool,
    responsiveLabel: PropTypes.bool,
    isFull: PropTypes.bool,

    className: PropTypes.string,
};

Action.contextType = PageContext;

export default Action;
